// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
function StartFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyDTonRdBHu9WCGRZ9mGLYtwzu1W6sEeMt8',
    authDomain: 'smartshutter-2133c.firebaseapp.com',
    databaseURL: 'https://smartshutter-2133c-default-rtdb.firebaseio.com',
    projectId: 'smartshutter-2133c',
    storageBucket: 'smartshutter-2133c.appspot.com',
    messagingSenderId: '300742723457',
    appId: '1:300742723457:web:832141caf87173828c8a80',
    measurementId: 'G-Z5F2CN0WH6',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return getDatabase(app);
}
export default StartFirebase;
