// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useState, useEffect, useRef } from 'react';
import { FaAngleDoubleUp, FaAngleDoubleDown, FaCloud } from 'react-icons/fa';
import { FaPause, FaKeycdn } from 'react-icons/fa6';
import { RiRemoteControlFill } from 'react-icons/ri';
import { GiButtonFinger } from 'react-icons/gi';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';

import StartFirebase from '../../../firebase/index';
import {
  ref,
  set,
  get,
  update,
  remove,
  child,
  onValue,
} from 'firebase/database';

export default function UserReports() {
  // Temp
  const [isHolding, setIsHolding] = useState(false);
  const holdTimeout = useRef(null);

  const handleButtonClick1 = () => {
    console.log('Roll Up Button is clicked');
    update(ref(db, 'SmartShutter/Hardware/WebControl'), {
      RollUp: !WebControlData.RollUp,
      Stop: WebControlData.RollUp,
      RollDown: false,
    });

    update(ref(db, 'SmartShutter/Hardware'), {
      WebControlHardware: WebControlData.RollUp ? 'stop' : 'up',
    });
  };

  const handleMouseDown1 = () => {
    holdTimeout.current = setTimeout(() => {
      console.log('Roll up Button is held');
      update(ref(db, 'SmartShutter/Hardware/WebControl'), {
        RollUp: true,
        Stop: false,
        RollDown: false,
      });

      update(ref(db, 'SmartShutter/Hardware'), {
        WebControlHardware: 'up',
      });
      setIsHolding(true);
    }, 500);
  };

  const handleMouseUp1 = () => {
    clearTimeout(holdTimeout.current);
    if (isHolding) {
      console.log('Roll up Button is released');
      update(ref(db, 'SmartShutter/Hardware/WebControl'), {
        RollUp: false,
        Stop: true,
        RollDown: false,
      });

      update(ref(db, 'SmartShutter/Hardware'), {
        WebControlHardware: 'stop',
      });
      setIsHolding(false);
    }
  };

  const handleButtonClick2 = () => {
    console.log('Roll Down Button is clicked');
    update(ref(db, 'SmartShutter/Hardware/WebControl'), {
      RollUp: false,
      Stop: WebControlData.RollDown,
      RollDown: !WebControlData.RollDown,
    });

    update(ref(db, 'SmartShutter/Hardware'), {
      WebControlHardware: WebControlData.RollDown ? 'stop' : 'down',
    });
  };

  const handleMouseDown2 = () => {
    holdTimeout.current = setTimeout(() => {
      console.log('Roll down Button is held');
      update(ref(db, 'SmartShutter/Hardware/WebControl'), {
        RollUp: false,
        Stop: false,
        RollDown: true,
      });

      update(ref(db, 'SmartShutter/Hardware'), {
        WebControlHardware: 'down',
      });
      setIsHolding(true);
    }, 500);
  };

  const handleMouseUp2 = () => {
    clearTimeout(holdTimeout.current);
    if (isHolding) {
      console.log('Roll down Button is released');
      update(ref(db, 'SmartShutter/Hardware/WebControl'), {
        RollUp: false,
        Stop: true,
        RollDown: false,
      });

      update(ref(db, 'SmartShutter/Hardware'), {
        WebControlHardware: 'stop',
      });
      setIsHolding(false);
    }
  };
  // Firebase Integration
  const [SpinnerLoading, setSpinnerLoading] = useState(false);

  const [WebControlData, setWebControlData] = useState({
    RollDown: false,
    RollUp: false,
    Stop: false,
  });

  const [ControlModes, setControlModes] = useState({
    KeyControl: false,
    RemoteControl: false,
    WebControl: false,
  });

  const db = StartFirebase();
  function dataFetch() {
    const dbRef = ref(db, 'SmartShutter/Hardware');
    onValue(dbRef, (snapshot) => {
      let records = [];
      // console.log(snapshot);
      snapshot.forEach((childSnapshot) => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({ key: keyName, data: data });
      });
      setControlModes(records[0]?.data);
      setWebControlData(records[1]?.data);
      // console.log(records);
    });
  }
  useEffect(() => {
    dataFetch();
  }, []);

  function formatMillis(millis) {
    // Calculate total seconds
    const totalSeconds = Math.floor(millis / 1000);
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format the components to always show two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Return formatted string
    return `${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`;
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          borderRadius: 'inherit',
          background: 'inherit',
          marginBottom: '0.5rem',
        }}
      >
        Web Control
      </div>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
        gap="20px"
        mb="20px"
        style={
          !ControlModes.WebControl
            ? {
                pointerEvents: 'none',
                pointer: 'cursor',
                opacity: '0.5',
              }
            : {}
        }
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              onMouseDown={handleMouseDown1}
              onMouseUp={handleMouseUp1}
              onMouseLeave={handleMouseUp1}
              onDoubleClick={handleButtonClick1}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaAngleDoubleUp}
                  color={
                    WebControlData.RollUp | WebControlData.TriggerUp
                      ? brandColor
                      : 'gray'
                  }
                  // color="gray"
                />
              }
            />
          }
          name="Roll up"
          value={`${
            WebControlData.RollUp | WebControlData.TriggerUp ? 'ON' : 'OFF'
          }`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              onDoubleClick={() => {
                console.log('Roll Stop Changed', !WebControlData.Stop);
                update(ref(db, 'SmartShutter/Hardware/WebControl'), {
                  Stop: true,
                  RollUp: false,
                  RollDown: false,
                });

                update(ref(db, 'SmartShutter/Hardware'), {
                  WebControlHardware: 'stop',
                });
              }}
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaPause}
                  color={WebControlData.Stop ? brandColor : 'gray'}
                />
              }
            />
          }
          name="Stop"
          value={`${WebControlData.Stop ? 'Halted' : 'Operational'}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              onMouseDown={handleMouseDown2}
              onMouseUp={handleMouseUp2}
              onMouseLeave={handleMouseUp2}
              onDoubleClick={handleButtonClick2}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaAngleDoubleDown}
                  color={
                    WebControlData.RollDown | WebControlData.TriggerDown
                      ? brandColor
                      : 'gray'
                  }
                />
              }
            />
          }
          name="Roll down"
          value={`${WebControlData.RollDown ? 'ON' : 'OFF'}`}
        />
      </SimpleGrid>
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          borderRadius: 'inherit',
          background: 'inherit',
          marginBottom: '0.5rem',
        }}
      >
        Control Modes
      </div>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              onClick={() => {
                console.log('WebControl Changed', !ControlModes.WebControl);
                update(ref(db, 'SmartShutter/Hardware/ControlModes'), {
                  WebControl: !ControlModes.WebControl,
                });
                update(ref(db, 'SmartShutter/Hardware/WebControl'), {
                  Stop: true,
                  RollUp: false,
                  RollDown: false,
                });
              }}
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaCloud}
                  color={ControlModes.WebControl ? brandColor : 'gray'}
                />
              }
            />
          }
          name="Web Control"
          value={`${ControlModes.WebControl ? 'Enabled' : 'Disabled'}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              onClick={() => {
                console.log(
                  'RemoteControl Changed',
                  !ControlModes.RemoteControl,
                );
                update(ref(db, 'SmartShutter/Hardware/ControlModes'), {
                  RemoteControl: !ControlModes.RemoteControl,
                });
              }}
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={RiRemoteControlFill}
                  color={ControlModes.RemoteControl ? brandColor : 'gray'}
                />
              }
            />
          }
          name="Remote Control"
          value={`${ControlModes.RemoteControl ? 'Enabled' : 'Disabled'}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              onClick={() => {
                console.log('KeyControl Changed', !ControlModes.KeyControl);
                update(ref(db, 'SmartShutter/Hardware/ControlModes'), {
                  KeyControl: !ControlModes.KeyControl,
                });
              }}
              w="56px"
              h="56px"
              bg={boxBg}
              cursor={'pointer'}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaKeycdn}
                  color={ControlModes.KeyControl ? brandColor : 'gray'}
                />
              }
            />
          }
          name="Key Control"
          value={`${ControlModes.KeyControl ? 'Enabled' : 'Disabled'}`}
        />
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
